/* This example requires Tailwind CSS v2.0+ */
import { TruckIcon } from "@heroicons/react/24/solid";
import Image from "next/image";
import { useRouter } from "../node_modules/next/router";
import { CATEGORIES, SUBCATEGORIES } from "../utils/constants";

export default function CategoryGrid() {
  const router = useRouter();

  const goToList = (filterValue, filterId = "category") => {
    const pathname = `/autosusados`;
    router.push({
      pathname,
      query: { [filterId]: filterValue },
    });
  };

  return (
    <div className="max-w-7xl px-4">
      <h2 className="text-2xl font-extrabold tracking-tight text-gray-900">
        Buscá autos usados por estilo
      </h2>
      <ul
        role="list"
        className="grid grid-cols-2 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 my-2 py-6"
      >
        {Object.values(CATEGORIES).map((category) => (
          <li
            key={category.label}
            className="col-span-1 flex flex-col text-center bg-white rounded-lg shadow hover:bg-gray-100 cursor-pointer"
            onClick={() => goToList(category.id)}
          >
            <div className="flex-1 flex flex-col p-8 items-center justify-center">
              <div className="h-16 w-28 sm:w-40 relative">
                <Image
                  src={category.icon}
                  fill
                  sizes="(max-width: 768px) 40vw, (max-width: 1200px) 33vw, 25vw"
                  alt={`Imagen de categoría ${CATEGORIES[category.id].label}`}
                />
              </div>
              <h3 className="mt-1 text-gray-900 text-sm font-medium">
                {category.label}
              </h3>
            </div>
          </li>
        ))}
      </ul>
      {/* <h2 className="text-2xl font-extrabold tracking-tight text-gray-900">
        Otras categorías
      </h2>
      <ul
        role="list"
        className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 my-2 py-6"
      >
        {Object.values(SUBCATEGORIES).map((subcategory) => (
          <li
            key={subcategory.label}
            className="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200 hover:bg-gray-100 cursor-pointer"
            onClick={() => goToList(subcategory.id, subcategory.filterId)}
          >
            <div className="flex-1 flex flex-col p-8 items-center">
              <TruckIcon className="text-gray-400 h-24 w-24" />
              <h3 className="mt-6 text-gray-900 text-sm font-medium">
                {subcategory.label}
              </h3>
            </div>
          </li>
        ))}
      </ul> */}
    </div>
  );
}
